import React from "react"

// Styles
import RTEStyles from "./RTE.module.styl"

const RTE = props => {
    return (
        <div
            className={RTEStyles.rte}
            dangerouslySetInnerHTML={{
                __html: `${props.dangerouslySetInnerHTML.__html}`,
            }}
        ></div>
    )
}

export default RTE
