import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

// Comps
import Container from "../components/containers/Container"
import RTE from "../components/rtes/RTE"

// Layout
import { Row, Cell } from "../components/layout/Layout"

// Styles
import postStyles from "./PostTemplate.module.styl"

// MISC
import Metas from "../components/metas/Metas"

// Libs
import * as moment from "moment"
import "moment/locale/fr"

class PostTemplate extends Component {
    constructor(props) {
        super(props)

        moment.locale("fr")

        this.state = {
            data: this.props.data.allCockpitPosts.nodes[0],
            diffDays: Math.floor(
                moment(
                    this.props.data.allCockpitPosts.nodes[0].cockpitCreated
                ).diff(moment([2020, 2, 13]), "days", true)
            ),
        }
    }

    render() {
        return (
            <Container location={this.props.location}>
                <Metas
                    title={this.state.data.titre.value}
                    description={`${this.state.diffDays}${
                        this.state.diffDays > 1 ? "e" : "er"
                    } jour`}
                    image={
                        this.state.data.image.value.childImageSharp.fluid.src
                    }
                    url={this.props.location.href}
                />
                <Row>
                    <Cell start="0" end="8">
                        <article className={postStyles.Post}>
                            <div className={postStyles.Post__top}>
                                <h1
                                    className={`${postStyles.Post__title} teasing-1`}
                                >
                                    {this.state.data.titre.value}
                                </h1>
                                <div className={postStyles.Post__meta}>
                                    <time
                                        dateTime={
                                            this.state.data.cockpitCreated
                                        }
                                        className={`${postStyles.Post__date} small`}
                                    >
                                        Le{" "}
                                        {moment(
                                            this.state.data.cockpitCreated
                                        ).format("LL")}
                                    </time>
                                    <span
                                        className={`${postStyles.Post__count} small u-c-white`}
                                    >
                                        {this.state.diffDays}
                                        <sup>{`${
                                            this.state.diffDays > 1 ? "e" : "er"
                                        }`}</sup>
                                        &nbsp;jour
                                    </span>
                                </div>
                            </div>

                            <div className={postStyles.Post__body}>
                                <Img
                                    fluid={
                                        this.state.data.image.value
                                            .childImageSharp.fluid
                                    }
                                    className={postStyles.Post__image}
                                />
                            </div>

                            <div className={postStyles.Post__bottom}>
                                <RTE
                                    dangerouslySetInnerHTML={{
                                        __html: `${this.state.data.texte.value}`,
                                    }}
                                ></RTE>
                            </div>
                        </article>
                    </Cell>
                </Row>
            </Container>
        )
    }
}

export const query = graphql`
    query PostTemplateQuery($skip: Int!, $limit: Int!) {
        allCockpitPosts(limit: $limit, skip: $skip) {
            nodes {
                id
                titre {
                    value
                }
                texte {
                    value
                }
                cockpitCreated
                image {
                    value {
                        publicURL
                        childImageSharp {
                            fluid(
                                quality: 85
                                maxWidth: 800
                                duotone: {
                                    highlight: "#FFAD00"
                                    shadow: "#36200C"
                                }
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`

export default PostTemplate
